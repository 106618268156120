import type { FunctionComponent, ReactElement } from 'react'

import Editorial from './themeHeaders/Editorial'
import EditorialThreeRowsHeader from '../epages.editorial/themeHeaders/ThreeRowsHeader'
import EditorialTwoRowsHeader from '../epages.editorial/themeHeaders/TwoRowsHeader'
import Essence from './themeHeaders/Essence'
import Limelight from './themeHeaders/Limelight'
import OneRowHeader from './themeHeaders/OneRowHeader'
import OneRowLogoLeftHeader from './themeHeaders/OneRowLogoLeftHeader'
import Structure from './themeHeaders/Structure'
import ThreeRowHeader from './themeHeaders/ThreeRowHeader'
import TwoRowsHeader from './themeHeaders/TwoRowsHeader'
import TwoRowsNestedMenuHeader from './themeHeaders/TwoRowsNestedMenuHeader'
import Uptown from './themeHeaders/Uptown'
import VisionOneRowHeader from '../epages.vision/themeHeaders/OneRowHeader'
import VisionTwoRowsHeader from '../epages.vision/themeHeaders/TwoRowsHeader'

const headerMap: Record<ThemeHeaderTypes, FunctionComponent<React.PropsWithChildren<any>>> = {
  vision: OneRowLogoLeftHeader,
  oneRow: OneRowHeader,
  twoRows: TwoRowsHeader,
  uptown: Uptown,
  neutral: ThreeRowHeader,
  editorial: Editorial,
  essence: Essence,
  structure: Structure,
  limelight: Limelight,
  threeRows: ThreeRowHeader,
  oneRowLogoLeft: OneRowLogoLeftHeader,
  twoRowsNestedMenu: TwoRowsNestedMenuHeader,
}

type Props = {
  shop: Core.FullShop
  themeSettings: Theme.Settings
}
export default function ThemeHeader(props: Props): ReactElement {
  const {
    themeSettings,
    shop: { theme },
  } = props

  const selectedHeader: ThemeHeaderTypes = themeSettings?.themeHeader?.headerType
  let Element
  if (selectedHeader === 'twoRows' && theme.name.startsWith('epages.vision')) {
    Element = VisionTwoRowsHeader
  } else if (selectedHeader === 'oneRow' && theme.name.startsWith('epages.vision')) {
    Element = VisionOneRowHeader
  } else if (selectedHeader === 'twoRows' && theme.name.startsWith('epages.editorial')) {
    Element = EditorialTwoRowsHeader
  } else if (selectedHeader === 'threeRows' && theme.name.startsWith('epages.editorial')) {
    Element = EditorialThreeRowsHeader
  } else {
    Element = headerMap[selectedHeader]
  }

  return <Element {...props} />
}
