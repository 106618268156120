import { Component, createContext, useContext } from 'react'
import type { ComponentPropsWithoutRef, ContextType, MouseEvent, ReactElement, ReactNode } from 'react'
import type { TFunction } from 'i18next'
import cc from 'classcat'

import Link from '../../templateComponents/Link'
import translate from '../../../utils/translate'

function loopNodeList(nodeList: HTMLCollection, fn: (item: HTMLElement) => void) {
  for (let i = 0, l = nodeList.length; i < l; i++) {
    fn(nodeList.item(i) as HTMLElement)
  }
}

function repositionSubMenus(
  item: HTMLElement,
  navigationRulerRect: DOMRect,
  level = 0,
  flowDirection: 'right' | 'left' = 'right',
) {
  if (item.classList.contains('main-menu') || item.classList.contains('sub-menu')) {
    if (level <= 1) {
      // loopNodeList(item.children, child => repositionSubMenus(child, navigationRulerRect, level + 1, 'right'));
      // handeling the first layer of sub menus
      switch (flowDirection) {
        case 'right': {
          // default position to flow right
          item.style.right = ''
          const rect = item.getBoundingClientRect()

          // reposition if exceeding viewport
          if (rect.right <= navigationRulerRect.right) {
            loopNodeList(item.children, (child) => repositionSubMenus(child, navigationRulerRect, level + 1, 'right'))
          } else {
            item.style.right = '0'
            loopNodeList(item.children, (child) => repositionSubMenus(child, navigationRulerRect, level + 1, 'left'))
          }

          break
        }
        case 'left': {
          // default position to flow left
          item.style.right = '0'
          const rect = item.getBoundingClientRect()

          // reposition if exceeding viewport
          if (rect.left >= navigationRulerRect.left) {
            loopNodeList(item.children, (child) => repositionSubMenus(child, navigationRulerRect, level + 1, 'left'))
          } else {
            item.style.right = ''
            loopNodeList(item.children, (child) => repositionSubMenus(child, navigationRulerRect, level + 1, 'right'))
          }

          break
        }
        default:
          throw new Error(`Unsupported flow direction ${flowDirection}`)
      }
    } else {
      // handling all other layers of sub menus
      switch (flowDirection) {
        case 'right': {
          // default position to flow right
          item.style.left = ''
          const rect = item.getBoundingClientRect()

          // reposition if exceeding viewport
          if (rect.right <= navigationRulerRect.right) {
            loopNodeList(item.children, (child) => repositionSubMenus(child, navigationRulerRect, level + 1, 'right'))
          } else {
            item.style.left = `${-item.getBoundingClientRect().width}px`
            loopNodeList(item.children, (child) => repositionSubMenus(child, navigationRulerRect, level + 1, 'left'))
          }

          break
        }
        case 'left': {
          // default position to flow left
          item.style.left = `${-item.getBoundingClientRect().width}px`
          const rect = item.getBoundingClientRect()

          // reposition if exceeding viewport
          if (rect.left >= navigationRulerRect.left) {
            loopNodeList(item.children, (child) => repositionSubMenus(child, navigationRulerRect, level + 1, 'left'))
          } else {
            item.style.left = ''
            loopNodeList(item.children, (child) => repositionSubMenus(child, navigationRulerRect, level + 1, 'right'))
          }

          break
        }
        default:
          throw new Error(`Unsupported flow direction ${flowDirection}`)
      }
    }
  } else {
    loopNodeList(item.children, (child) => repositionSubMenus(child, navigationRulerRect, level, flowDirection))
  }
}

function extendItems(
  items: Frontend.NestedPage[],
  active: Frontend.NestedPage | null,
  opened: Frontend.NestedPage[],
): Frontend.ExtendedNestedPage[] {
  return items.reduce<Frontend.ExtendedNestedPage[]>((acc, item) => {
    return acc.concat([
      Object.assign({}, item, {
        active: Boolean(active && active.id === item.id),
        opened: Boolean(opened.find((i) => i.id === item.id)),
        children: extendItems(item.children, active, opened),
      }),
    ])
  }, [])
}

function traceOpened(items: Frontend.NestedPage[], active: Frontend.NestedPage): Frontend.NestedPage[] | null {
  return items.reduce((trace, item) => {
    if (item.id === active.id) {
      return [item]
    }

    const subTrace = traceOpened(item.children, active)
    if (subTrace) {
      return [item].concat(subTrace)
    }

    return trace || null
  }, null)
}

// NestedMenu uses state that is read/set by a parent component.
/* eslint-disable react/no-unused-state */

const NestedMenuContext = createContext<
  | {
      instance: InstanceType<typeof NestedMenu>
      state: NestedMenuState
    }
  | undefined
>(undefined)

type NestedMenuProps = {
  desktopMediaQuery: string
  children: ReactNode
  className?: string
}

type NestedMenuState = {
  isDesktop: boolean
  active: Frontend.NestedPage | null
  opened: Frontend.NestedPage[]
  menuOpen: boolean
  touch: boolean
}

class NestedMenu extends Component<NestedMenuProps, NestedMenuState> {
  private currentLocation: string
  private element: HTMLDivElement
  private rulerElement: HTMLDivElement

  state: NestedMenuState = {
    isDesktop: false,
    active: null,
    opened: [],
    menuOpen: false,
    touch: false,
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleUpdate)
    this.handleUpdate()

    this.currentLocation = window.location.href
  }

  componentDidUpdate() {
    // close menu on changing the adress
    if (this.currentLocation !== window.location.href) {
      this.currentLocation = window.location.href
      this.resetState()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleUpdate)
  }

  handleUpdate = () => {
    const mainMenu = this.element && this.element.querySelector<HTMLElement>('.main-menu')
    const nestedMenuRuler = this.rulerElement

    if (mainMenu && nestedMenuRuler) {
      repositionSubMenus(mainMenu, nestedMenuRuler.getBoundingClientRect())
    }

    this.setState({
      isDesktop: window.matchMedia(this.props.desktopMediaQuery).matches,
    })
  }

  handleMouseLeave = () => {
    if (this.state.isDesktop) {
      this.resetState()
    }
  }

  handleTouchStart = () => {
    this.setState({ touch: true })
  }

  resetState = () => {
    this.setState({
      active: null,
      opened: [],
      menuOpen: false,
      touch: false,
    })
  }

  toggleMobile = () => {
    if (this.state.menuOpen) {
      this.closeMobile()
    } else {
      this.setState({
        active: null,
        opened: [],
        menuOpen: true,
      })
    }
  }

  closeMobile = () => {
    this.setState({
      active: null,
      opened: [],
      menuOpen: false,
    })
  }

  render() {
    return (
      // Passing "state" alongside the component instance so that consumers get re-rendered when updating
      <NestedMenuContext.Provider value={{ instance: this, state: this.state }}>
        <div
          ref={(node: HTMLDivElement) => (this.element = node)}
          className={cc(['nested-menu', this.props.className, { open: this.state.menuOpen }])}
          onMouseLeave={this.handleMouseLeave}
          onTouchStart={this.handleTouchStart}
        >
          <div ref={(node: HTMLDivElement) => (this.rulerElement = node)} className="nested-menu-ruler" />
          {this.props.children}
        </div>
      </NestedMenuContext.Provider>
    )
  }
}

interface MobileToggleProps extends ComponentPropsWithoutRef<'button'> {
  t: TFunction
}

function MobileToggle({ t, ...props }: MobileToggleProps): ReactElement {
  const nestedMenu = useContext(NestedMenuContext)
  const isOpen = nestedMenu?.state.menuOpen ?? false

  return (
    <button
      {...props}
      aria-label={t(`${isOpen ? 'closeMainMenuButton' : 'openMainMenuButton'}.accessibilityLabel`)}
      aria-controls="main-menu-nested"
      aria-expanded={isOpen}
      onClick={() => {
        nestedMenu?.instance.toggleMobile()
      }}
    />
  )
}

type MenuProps = {
  items: Frontend.NestedPage[]
}

class Menu extends Component<MenuProps> {
  static contextType = NestedMenuContext
  context!: ContextType<typeof NestedMenuContext>

  get nestedMenu() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.context!.instance
  }

  componentDidUpdate(prevProps: MenuProps) {
    // Update display of sub menus when items changed.
    // E.g. on initial lazy loading of items in the storefront, or when
    // adding/removing/changing pages or their nesting level in the editor.
    if (this.props.items !== prevProps.items) {
      window.requestAnimationFrame(() => this.nestedMenu.handleUpdate())
    }
  }

  handleItemMouseEnter = (item: Frontend.NestedPage) => {
    if (this.nestedMenu.state.isDesktop && !this.nestedMenu.state.touch) {
      this.activateItem(item)
    }
  }

  handleItemMouseLeave = (item: Frontend.NestedPage) => {
    if (this.nestedMenu.state.isDesktop) {
      this.deactivateItem(item)
    }
  }

  handleItemClick = (event: MouseEvent, item: Frontend.NestedPage) => {
    const isActiveItem = this.nestedMenu.state.active && this.nestedMenu.state.active.id === item.id

    if (isActiveItem || !item.children.length) {
      this.nestedMenu.resetState()
    } else {
      event.preventDefault()
      event.stopPropagation()

      this.activateItem(item)
    }
  }

  handleItemToggleClick = (event: MouseEvent<HTMLSpanElement>, item: Frontend.NestedPage) => {
    event.preventDefault()

    // prevent closing the whole mobile menu by closing a sub menu entry
    event.stopPropagation()

    if (!this.nestedMenu.state.opened.find((i: Frontend.NestedPage) => i.id === item.id)) {
      this.activateItem(item)
    } else {
      this.deactivateItem(item)
    }
  }

  activateItem = (item: Frontend.NestedPage) => {
    const opened = traceOpened(this.props.items, item) || []

    this.nestedMenu.setState({
      active: item,
      opened,
      menuOpen: true,
    })
  }

  deactivateItem = (item: Frontend.NestedPage) => {
    const opened = traceOpened(this.props.items, item) || []

    this.nestedMenu.setState({
      active: opened[opened.length - 2] || null,
      opened: opened.slice(0, opened.length - 1),
    })
  }

  render() {
    const { active, opened } = this.nestedMenu.state

    return (
      <div id="main-menu-nested" className={cc(['main-menu-wrapper', { show: this.nestedMenu.state.menuOpen }])}>
        <div className="main-menu-overlay" onClick={this.nestedMenu.closeMobile} />
        {this.renderLayer(extendItems(this.props.items, active, opened), 0)}
      </div>
    )
  }

  renderLayer = (items: Frontend.ExtendedNestedPage[], level: number) => {
    return (
      <ul className={cc([{ 'main-menu': level === 0, 'sub-menu': level > 0 }])}>
        {items.map((item) => {
          const hasSubMenu = item.children.length > 0

          return (
            <li
              key={item.id}
              className={cc({
                active: item.opened,
                'navigation-active': item.isInBreadcrumb,
              })}
              onMouseEnter={() => this.handleItemMouseEnter(item)}
              onMouseLeave={() => this.handleItemMouseLeave(item)}
            >
              <Link to={item.href} onClick={(e) => this.handleItemClick(e, item)}>
                <span>{item.title}</span>

                {hasSubMenu && (
                  <span
                    className={cc([
                      {
                        opened: item.opened,
                        'nested-sub-menu': level >= 1,
                        'main-menu-nested': level === 0,
                      },
                    ])}
                    onClick={(e) => this.handleItemToggleClick(e, item)}
                  />
                )}
              </Link>

              {hasSubMenu ? this.renderLayer(item.children, level + 1) : null}
            </li>
          )
        })}
      </ul>
    )
  }
}

export default Object.assign(NestedMenu, {
  Menu,
  MobileToggle: translate('components.storefrontMainMenuComponent')(MobileToggle),
})
