import PropTypes from 'prop-types'

import Element from '../Element'

export function NestedBlockWithHeadline({ config, editorView, data, onDataChange }) {
  return (
    <>
      <Element
        config={config}
        editorView={editorView}
        element={data.get('headline')}
        onElementChange={(element) => onDataChange(data.set('headline', element))}
      />

      {data.get('columns').map((column, index) => (
        <div key={index}>
          <Element
            config={config}
            editorView={editorView}
            element={column}
            onElementChange={(element) => onDataChange(data.setIn(['columns', index], element))}
          />
        </div>
      ))}
    </>
  )
}

NestedBlockWithHeadline.propTypes = {
  config: PropTypes.any.isRequired,
  editorView: PropTypes.any.isRequired,
  data: PropTypes.any.isRequired,
  onDataChange: PropTypes.func.isRequired,
}

NestedBlockWithHeadline.getElements = (data) => [data.headline, ...data.columns]

function BlockWithHeadline(props) {
  return (
    <div className="dali-grid-row">
      <div className="dali-grid-column">
        <NestedBlockWithHeadline {...props} />
      </div>
    </div>
  )
}

BlockWithHeadline.getElements = (data) => [data.headline, ...data.columns]

export default BlockWithHeadline
