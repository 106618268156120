export type CartActionTypes =
  | ResolvedApiAction<LoadCart>
  | ResolvedApiAction<AddToCart>
  | ResolvedApiAction<SetCartBillingAddress>
  | ResolvedApiAction<SetCartShippingAddress>
  | ResolvedApiAction<AssignCustomerToCart>
  | SetCustomerCommentAction
  | ResolvedApiAction<LoadCartServiceableCountries>
  | ResolvedApiAction<LoadAllCountries>
  | ResolvedApiAction<LoadCartApplicableShippingMethods>
  | ResolvedApiAction<LoadCartApplicablePaymentMethods>
  | ResolvedApiAction<SetCartPaymentMethod>
  | ResolvedApiAction<SetCartCreatePayment>
  | ResolvedApiAction<SetCartCreatePaymentAndOrder>
  | ResolvedApiAction<SetCartShippingMethod>
  | ResolvedApiAction<UnsetCartPickupOption>
  | ResolvedApiAction<OrderCart>
  | SetCartBusyStateAction
  | SetCartCouponCodeAction
  | ResolvedApiAction<ClearCartCouponError>
  | SetCartCouponErrorAction
  | SetCartQuantitiesAction
  | ResolvedApiAction<UpdateLineItemQuantity>
  | ResolvedApiAction<RemoveCoupon>
  | ResolvedApiAction<RedeemCoupon>
  | SetCheckoutDataAction
  | SetChannelsAction
  | SetCartBillingPhoneAction
  | SetApplicablePickupOptionsAction
  | SetCartPickupOptionAction
  | UnsetCartPickupOption

type LoadCart = {
  type: typeof LOAD_CART
  response: Core.Cart
}
export const LOAD_CART = 'LOAD_CART'
export const LOAD_CART_SUCCESS = 'LOAD_CART_SUCCESS'
export const LOAD_CART_FAILURE = 'LOAD_CART_FAILURE'
export function loadCart(options?: ActionOptions): ApiAction<LoadCart> {
  return {
    type: LOAD_CART,
    callApi: (api, _payload, { locale }) => api.get('/api/v2/cart', { params: { locale } }).then((res) => res.data),
    options,
  }
}

type AddToCart = {
  type: typeof ADD_TO_CART
  payload: {
    productId: string
    quantity: number
    customizedText?: string
  }
  response: Core.Cart
}
export const ADD_TO_CART = 'ADD_TO_CART'
export const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS'
export const ADD_TO_CART_FAILURE = 'ADD_TO_CART_FAILURE'
export function addToCart(
  productId: string,
  quantity: number,
  customizedText?: string,
  options?: ActionOptions,
): ApiAction<AddToCart> {
  return {
    type: ADD_TO_CART,
    payload: { productId, quantity, customizedText },
    callApi: (api, { productId, quantity }, { locale }) =>
      api
        .post(
          '/api/v2/cart',
          {
            productId,
            quantity,
            customizedText,
          },
          { params: { locale } },
        )
        .then((res) => res.data),
    options,
  }
}

type SetCartBillingAddress = {
  type: typeof SET_CART_BILLING_ADDRESS
  payload: {
    billingAddress: Core.Address
  }
  response: Core.Cart
}
export const SET_CART_BILLING_ADDRESS = 'SET_CART_BILLING_ADDRESS'
export const SET_CART_BILLING_ADDRESS_SUCCESS = 'SET_CART_BILLING_ADDRESS_SUCCESS'
export const SET_CART_BILLING_ADDRESS_FAILURE = 'SET_CART_BILLING_ADDRESS_FAILURE'
export function setCartBillingAddress(
  billingAddress: Core.Address,
  options?: ActionOptions,
): ApiAction<SetCartBillingAddress> {
  return {
    type: SET_CART_BILLING_ADDRESS,
    payload: { billingAddress },
    callApi: (api, { billingAddress }, { locale }) =>
      api
        .post(
          `/api/v2/cart/billing-address`,
          {
            billingAddress,
          },
          { params: { locale } },
        )
        .then((res) => res.data),
    options,
  }
}

type SetCartBillingPhoneAction = {
  type: typeof SET_CART_BILLING_PHONE
  response: string | null
}
export const SET_CART_BILLING_PHONE = 'SET_CART_BILLING_PHONE'
export function setCartBillingPhone(phone: string | null): SetCartBillingPhoneAction {
  return {
    type: SET_CART_BILLING_PHONE,
    response: phone,
  }
}
export function setCartBillingPhoneAsync(phone: string | null): GlobalAction {
  return async (dispatch, getState, api) => {
    const locale = getState().getIn(['views', 'locale'])
    const { data } = await api.post<string | null>('/api/v2/cart/billing-phone', { phone }, { params: { locale } })
    dispatch(setCartBillingPhone(data))
  }
}

type SetCartShippingAddress = {
  type: typeof SET_CART_SHIPPING_ADDRESS
  payload: {
    shippingAddress: Core.CUAddress
  }
  response: Core.Cart
}
export const SET_CART_SHIPPING_ADDRESS = 'SET_CART_SHIPPING_ADDRESS'
export const SET_CART_SHIPPING_ADDRESS_SUCCESS = 'SET_CART_SHIPPING_ADDRESS_SUCCESS'
export const SET_CART_SHIPPING_ADDRESS_FAILURE = 'SET_CART_SHIPPING_ADDRESS_FAILURE'
export function setCartShippingAddress(
  shippingAddress: Core.CUAddress,
  options?: ActionOptions,
): ApiAction<SetCartShippingAddress> {
  return {
    type: SET_CART_SHIPPING_ADDRESS,
    payload: { shippingAddress },
    callApi: (api, { shippingAddress }, { locale }) =>
      api
        .post(
          `/api/v2/cart/shipping-address`,
          {
            shippingAddress,
          },
          { params: { locale } },
        )
        .then((res) => res.data),
    options,
  }
}

type AssignCustomerToCart = {
  type: typeof ASSIGN_CUSTOMER_TO_CART
  response: Core.Cart
}
export const ASSIGN_CUSTOMER_TO_CART = 'ASSIGN_CUSTOMER_TO_CART'
export const ASSIGN_CUSTOMER_TO_CART_SUCCESS = 'ASSIGN_CUSTOMER_TO_CART_SUCCESS'
export const ASSIGN_CUSTOMER_TO_CART_FAILURE = 'ASSIGN_CUSTOMER_TO_CART_FAILURE'
export function assignCustomerToCart(options?: ActionOptions): ApiAction<AssignCustomerToCart> {
  return {
    type: ASSIGN_CUSTOMER_TO_CART,
    callApi: (api, _payload, { locale }) =>
      api.post(`/api/v2/cart/customer/assign`, { params: { locale } }).then((res) => res.data),
    options,
  }
}

type SetCustomerCommentAction = {
  type: typeof SET_CUSTOMER_COMMENT
  comment: string
  options?: ActionOptions
}
export const SET_CUSTOMER_COMMENT = 'SET_CUSTOMER_COMMENT'
export function setCustomerComment(comment: string, options?: ActionOptions): SetCustomerCommentAction {
  return {
    type: SET_CUSTOMER_COMMENT,
    comment,
    options,
  }
}

type LoadCartServiceableCountries = {
  type: typeof LOAD_CART_SERVICEABLE_COUNTRIES
  response: Core.ServiceableCountries[]
}
export const LOAD_CART_SERVICEABLE_COUNTRIES = 'LOAD_CART_SERVICEABLE_COUNTRIES'
export const LOAD_CART_SERVICEABLE_COUNTRIES_SUCCESS = 'LOAD_CART_SERVICEABLE_COUNTRIES_SUCCESS'
export const LOAD_CART_SERVICEABLE_COUNTRIES_FAILURE = 'LOAD_CART_SERVICEABLE_COUNTRIES_FAILURE'
export function loadCartServiceableCountries(options?: ActionOptions): ApiAction<LoadCartServiceableCountries> {
  return {
    type: LOAD_CART_SERVICEABLE_COUNTRIES,
    callApi: (api, _payload, { locale }) =>
      api.get(`/api/v2/cart/countries`, { params: { locale } }).then((res) => res.data),
    options,
  }
}

type LoadAllCountries = {
  type: typeof LOAD_ALL_COUNTRIES
  response: {
    value: string
    label: string
  }[]
}
export const LOAD_ALL_COUNTRIES = 'LOAD_ALL_COUNTRIES'
export const LOAD_ALL_COUNTRIES_SUCCESS = 'LOAD_ALL_COUNTRIES_SUCCESS'
export const LOAD_ALL_COUNTRIES_FAILURE = 'LOAD_ALL_COUNTRIES_FAILURE'
export function loadAllCountries(options?: ActionOptions): ApiAction<LoadAllCountries> {
  return {
    type: LOAD_ALL_COUNTRIES,
    callApi: (api) => api.get(`/api/v2/cart/all-countries`).then((res) => res.data),
    options,
  }
}

type LoadCartApplicableShippingMethods = {
  type: typeof LOAD_CART_APPLICABLE_SHIPPING_METHODS
  response: Core.ShippingMethod[]
}
export const LOAD_CART_APPLICABLE_SHIPPING_METHODS = 'LOAD_CART_APPLICABLE_SHIPPING_METHODS'
export const LOAD_CART_APPLICABLE_SHIPPING_METHODS_SUCCESS = 'LOAD_CART_APPLICABLE_SHIPPING_METHODS_SUCCESS'
export const LOAD_CART_APPLICABLE_SHIPPING_METHODS_FAILURE = 'LOAD_CART_APPLICABLE_SHIPPING_METHODS_FAILURE'
export function loadCartApplicableShippingMethods(
  options?: ActionOptions,
): ApiAction<LoadCartApplicableShippingMethods> {
  return {
    type: LOAD_CART_APPLICABLE_SHIPPING_METHODS,
    callApi: (api, _payload, { locale }) =>
      api.get(`/api/v2/cart/shipping-methods`, { params: { locale } }).then((res) => res.data),
    options,
  }
}

type LoadCartApplicablePaymentMethods = {
  type: typeof LOAD_CART_APPLICABLE_PAYMENT_METHODS
  response: Core.PaymentMethod[]
}
export const LOAD_CART_APPLICABLE_PAYMENT_METHODS = 'LOAD_CART_APPLICABLE_PAYMENT_METHODS'
export const LOAD_CART_APPLICABLE_PAYMENT_METHODS_SUCCESS = 'LOAD_CART_APPLICABLE_PAYMENT_METHODS_SUCCESS'
export const LOAD_CART_APPLICABLE_PAYMENT_METHODS_FAILURE = 'LOAD_CART_APPLICABLE_PAYMENT_METHODS_FAILURE'
export function loadCartApplicablePaymentMethods(options?: ActionOptions): ApiAction<LoadCartApplicablePaymentMethods> {
  return {
    type: LOAD_CART_APPLICABLE_PAYMENT_METHODS,
    callApi: (api, _payload, { locale }) =>
      api.get(`/api/v2/cart/payment-methods`, { params: { locale } }).then((res) => res.data),
    options,
  }
}

type SetCartPaymentMethod = {
  type: typeof SET_CART_PAYMENT_METHOD
  payload: {
    paymentMethod: string
  }
  response: Core.Cart
}
export const SET_CART_PAYMENT_METHOD = 'SET_CART_PAYMENT_METHOD'
export const SET_CART_PAYMENT_METHOD_SUCCESS = 'SET_CART_PAYMENT_METHOD_SUCCESS'
export const SET_CART_PAYMENT_METHOD_FAILURE = 'SET_CART_PAYMENT_METHOD_FAILURE'
export function setCartPaymentMethod(paymentMethod: string, options?: ActionOptions): ApiAction<SetCartPaymentMethod> {
  return {
    type: SET_CART_PAYMENT_METHOD,
    payload: { paymentMethod },
    callApi: (api, { paymentMethod }, { locale }) =>
      api
        .post(
          `/api/v2/cart/payment-method`,
          {
            paymentMethod,
          },
          { params: { locale } },
        )
        .then((res) => res.data),
    options,
  }
}

type OrderUris = {
  approvalUri: string | null
  embeddedApprovalUri: string | null
}
type SetCartCreatePayment = {
  type: typeof SET_CART_CREATE_PAYMENT
  payload: {
    uris: { returnUri: string; cancelUri: string }
  }
  response: OrderUris | null
}
export const SET_CART_CREATE_PAYMENT = 'SET_CART_CREATE_PAYMENT'
export const SET_CART_CREATE_PAYMENT_SUCCESS = 'SET_CART_CREATE_PAYMENT_SUCCESS'
export const SET_CART_CREATE_PAYMENT_FAILURE = 'SET_CART_CREATE_PAYMENT_FAILURE'
export function setCartCreatePayment(
  uris: { returnUri: string; cancelUri: string },
  options?: ActionOptions,
): ApiAction<SetCartCreatePayment> {
  return {
    type: SET_CART_CREATE_PAYMENT,
    payload: { uris },
    callApi: (api, { uris }, { locale }) =>
      api.post(`/api/v2/cart/create-payment`, uris, { params: { locale } }).then((res) => res.data),
    options,
  }
}

type SetCartCreatePaymentAndOrder = {
  type: typeof SET_CART_CREATE_PAYMENT_AND_ORDER
  payload: {
    returnUri: string
    cancelUri: string
    customerComment: string
    termsAndConditionsExplicitlyAccepted: boolean
    salesChannel?: string
    marketingChannel?: string
    marketingSubchannel?: string
  }
  response: OrderUris | null
}
export const SET_CART_CREATE_PAYMENT_AND_ORDER = 'SET_CART_CREATE_PAYMENT_AND_ORDER'
export const SET_CART_CREATE_PAYMENT_AND_ORDER_SUCCESS = 'SET_CART_CREATE_PAYMENT_AND_ORDER_SUCCESS'
export const SET_CART_CREATE_PAYMENT_AND_ORDER_FAILURE = 'SET_CART_CREATE_PAYMENT_AND_ORDER_FAILURE'
export function setCartCreatePaymentAndOrder(
  payload: SetCartCreatePaymentAndOrder['payload'],
  options?: ActionOptions,
): ApiAction<SetCartCreatePaymentAndOrder> {
  return {
    type: SET_CART_CREATE_PAYMENT_AND_ORDER,
    payload,
    callApi: (api, payload, { locale }) =>
      api.post(`/api/v2/cart/create-payment-and-order`, payload, { params: { locale } }).then((res) => res.data),
    options,
  }
}

type SetCartShippingMethod = {
  type: typeof SET_CART_SHIPPING_METHOD
  payload: {
    shippingMethod: string
  }
  response: Core.Cart
}
export const SET_CART_SHIPPING_METHOD = 'SET_CART_SHIPPING_METHOD'
export const SET_CART_SHIPPING_METHOD_SUCCESS = 'SET_CART_SHIPPING_METHOD_SUCCESS'
export const SET_CART_SHIPPING_METHOD_FAILURE = 'SET_CART_SHIPPING_METHOD_FAILURE'
export function setCartShippingMethod(
  shippingMethod: string,
  options?: ActionOptions,
): ApiAction<SetCartShippingMethod> {
  return {
    type: SET_CART_SHIPPING_METHOD,
    payload: { shippingMethod },
    callApi: (api, { shippingMethod }, { locale }) =>
      api
        .post(
          `/api/v2/cart/shipping-method`,
          {
            shippingMethod,
          },
          { params: { locale } },
        )
        .then((res) => res.data),
    options,
  }
}

type OrderCart = {
  type: typeof ORDER_CART
  payload: {
    customerComment: string
    testOrder: boolean
    termsAndConditionsExplicitlyAccepted: boolean
    marketingChannel?: string
    marketingSubchannel?: string
  }
}
export const ORDER_CART = 'ORDER_CART'
export const ORDER_CART_SUCCESS = 'ORDER_CART_SUCCESS'
export const ORDER_CART_FAILURE = 'ORDER_CART_FAILURE'
export function orderCart(payload: OrderCart['payload'], options?: ActionOptions): ApiAction<OrderCart> {
  return {
    type: ORDER_CART,
    payload,
    callApi: (api, payload, { locale }) =>
      api.post(`/api/v2/cart/place-order`, payload, { params: { locale } }).then((res) => res.data),
    options,
  }
}

type SetCartBusyStateAction = {
  type: typeof SET_CART_BUSY_STATE
  busyState: boolean
}
export const SET_CART_BUSY_STATE = 'SET_CART_BUSY_STATE'
export function setCartBusyState(busyState: boolean): SetCartBusyStateAction {
  return {
    type: SET_CART_BUSY_STATE,
    busyState,
  }
}

type SetCartCouponCodeAction = {
  type: typeof SET_CART_COUPON_CODE
  couponCode: string
}
export const SET_CART_COUPON_CODE = 'SET_CART_COUPON_CODE'
export function setCartCouponCode(couponCode: string): SetCartCouponCodeAction {
  return {
    type: SET_CART_COUPON_CODE,
    couponCode,
  }
}

type ClearCartCouponError = {
  type: typeof CLEAR_CART_COUPON_ERROR
}
export const CLEAR_CART_COUPON_ERROR = 'CLEAR_CART_COUPON_ERROR'
export function clearCartCouponError(): ClearCartCouponError {
  return {
    type: CLEAR_CART_COUPON_ERROR,
  }
}

type SetCartCouponErrorAction = {
  type: typeof SET_CART_COUPON_ERROR
  hasCouponError: boolean
}
export const SET_CART_COUPON_ERROR = 'SET_CART_COUPON_ERROR'
export function setCartCouponError(hasCouponError: boolean): SetCartCouponErrorAction {
  return {
    type: SET_CART_COUPON_ERROR,
    hasCouponError,
  }
}

type SetCartQuantitiesAction = {
  type: typeof SET_CART_QUANTITIES
  quantities: {
    [lineItemId: string]: number
  }
}
export const SET_CART_QUANTITIES = 'SET_CART_QUANTITIES'
export function setCartQuantities(quantities: { [lineItemId: string]: number }): SetCartQuantitiesAction {
  return {
    type: SET_CART_QUANTITIES,
    quantities,
  }
}

type UpdateLineItemQuantity = {
  type: typeof UPDATE_LINE_ITEM_QUANTITY
  payload: {
    lineItemId: string
    productId: string
    quantity: number
    quantityDelta: number
    customizedText?: string
  }
  response: Core.Cart
}
export const UPDATE_LINE_ITEM_QUANTITY = 'UPDATE_LINE_ITEM_QUANTITY'
export const UPDATE_LINE_ITEM_QUANTITY_SUCCESS = 'UPDATE_LINE_ITEM_QUANTITY_SUCCESS'
export const UPDATE_LINE_ITEM_QUANTITY_FAILURE = 'UPDATE_LINE_ITEM_QUANTITY_FAILURE'
export function updateLineItemQuantity(
  lineItemId: string,
  productId: string,
  quantity: number,
  quantityDelta: number,
  customizedText?: string,
  options?: ActionOptions,
): ApiAction<UpdateLineItemQuantity> {
  return {
    type: UPDATE_LINE_ITEM_QUANTITY,
    payload: { lineItemId, productId, quantity, quantityDelta, customizedText },
    callApi: (api, { lineItemId, productId, quantity, quantityDelta, customizedText }, { locale }) =>
      api
        .put(
          `/api/v2/cart/line-items/${lineItemId}`,
          {
            productId,
            quantity,
            quantityDelta,
            customizedText,
          },
          { params: { locale } },
        )
        .then((res) => res.data),
    options,
  }
}

type RemoveCoupon = {
  type: typeof REMOVE_COUPON
}
export const REMOVE_COUPON = 'REMOVE_COUPON'
export const REMOVE_COUPON_SUCCESS = 'REMOVE_COUPON_SUCCESS'
export const REMOVE_COUPON_FAILURE = 'REMOVE_COUPON_FAILURE'
export function removeCoupon(): ApiAction<RemoveCoupon> {
  return {
    type: REMOVE_COUPON,
    callApi: (api, _payload, { locale }) =>
      api.delete('/api/v2/cart/coupon', { params: { locale } }).then((res) => res.data),
  }
}

type RedeemCoupon = {
  type: typeof REDEEM_COUPON
  payload: {
    couponCode: string
  }
  response: Core.Cart
}
export const REDEEM_COUPON = 'REDEEM_COUPON'
export const REDEEM_COUPON_SUCCESS = 'REDEEM_COUPON_SUCCESS'
export const REDEEM_COUPON_FAILURE = 'REDEEM_COUPON_FAILURE'
export function redeemCoupon(couponCode: string): ApiAction<RedeemCoupon> {
  return {
    type: REDEEM_COUPON,
    payload: {
      couponCode,
    },
    callApi: (api, { couponCode }, { locale }) =>
      api.post('/api/v2/cart/coupon', { couponCode }, { params: { locale } }).then((res) => res.data),
  }
}

type SetCheckoutDataAction = {
  type: typeof SET_CHECKOUT_DATA
  response: Core.Cart
}
export const SET_CHECKOUT_DATA = 'SET_CHECKOUT_DATA'
export function setCheckoutData(cart: Core.Cart): SetCheckoutDataAction {
  return {
    type: SET_CHECKOUT_DATA,
    response: cart,
  }
}
export const setCheckoutDataAsync =
  (email: string, firstName: string, lastName: string): GlobalAction =>
  async (dispatch, getState, api) => {
    const locale = getState().getIn(['views', 'locale'])
    const { data } = await api.post<Core.Cart>(
      '/api/v2/cart/checkout-data',
      { email, firstName, lastName },
      { params: { locale } },
    )
    dispatch(setCheckoutData(data))
  }

type SetChannelsAction = {
  type: typeof SET_CHANNELS
  marketingChannel: string
  marketingSubchannel?: string
}
export const SET_CHANNELS = 'SET_CHANNELS'
export function setChannels(marketingChannel: string, marketingSubchannel?: string): SetChannelsAction {
  return {
    type: SET_CHANNELS,
    marketingChannel,
    marketingSubchannel,
  }
}

type SetApplicablePickupOptionsAction = {
  type: typeof SET_CART_APPLICABLE_PICKUP_OPTIONS
  response: Beyond.EmbeddedPickupOption[]
}
export const SET_CART_APPLICABLE_PICKUP_OPTIONS = 'SET_CART_APPLICABLE_PICKUP_OPTIONS'
export function setApplicablePickupOptions(pickupOptions: Core.PickupOption[]): SetApplicablePickupOptionsAction {
  return {
    type: SET_CART_APPLICABLE_PICKUP_OPTIONS,
    response: pickupOptions,
  }
}
export const setApplicablePickupOptionsAsync = (): GlobalAction => async (dispatch, getState, api) => {
  const locale = getState().getIn(['views', 'locale'])
  const { data } = await api.get<Core.PickupOption[]>(`/api/v2/cart/pickup-options`, { params: { locale } })

  dispatch(setApplicablePickupOptions(data))
}

type SetCartPickupOptionAction = {
  type: typeof SET_CART_PICKUP_OPTION
  response: Core.Cart
}
export const SET_CART_PICKUP_OPTION = 'SET_CART_PICKUP_OPTION'
export function setCartPickupOption(cart: Core.Cart): SetCartPickupOptionAction {
  return {
    type: SET_CART_PICKUP_OPTION,
    response: cart,
  }
}
export const setCartPickupOptionAsync =
  (pickupOption: string): GlobalAction =>
  async (dispatch, getState, api) => {
    const locale = getState().getIn(['views', 'locale'])
    const { data } = await api.post<Core.Cart>('/api/v2/cart/pickup-option', { pickupOption }, { params: { locale } })

    dispatch(setCartPickupOption(data))
  }

type UnsetCartPickupOption = {
  type: typeof UNSET_CART_PICKUP_OPTION
}
export const UNSET_CART_PICKUP_OPTION = 'UNSET_CART_PICKUP_OPTION'
export const UNSET_CART_PICKUP_OPTION_SUCCESS = 'UNSET_CART_PICKUP_OPTION_SUCCESS'
export const UNSET_CART_PICKUP_OPTION_FAILURE = 'UNSET_CART_PICKUP_OPTION_FAILURE'
export function unsetCartPickupOption(): ApiAction<UnsetCartPickupOption> {
  return {
    type: UNSET_CART_PICKUP_OPTION,
    callApi: (api, _payload, { locale }) =>
      api.delete('/api/v2/cart/pickup-option', { params: { locale } }).then((res) => res.data),
  }
}
