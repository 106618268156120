import React, { Fragment, useEffect, useRef, useState } from 'react'
import { func, node, string } from 'prop-types'
import { throttle } from 'lodash'
import cc from 'classcat'

export const useIsInView = () => {
  const ref = useRef()
  const [inView, setInView] = useState(true)

  useEffect(() => {
    const scrollParent = document.querySelector('.editor-body-wrapper') || window
    const handleScroll = throttle(() => {
      if (ref.current) setInView(ref.current.getBoundingClientRect().top > 0)
    }, 200)

    scrollParent.addEventListener('scroll', handleScroll, { passive: true })
    handleScroll()

    return () => scrollParent.removeEventListener('scroll', handleScroll)
  })

  return [ref, inView]
}

const ToolbarTop = ({ onScrollIntoView, children, className }) => {
  const [refTopbar, inView] = useIsInView()

  useEffect(() => {
    onScrollIntoView(inView)
  }, [inView]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <div ref={refTopbar} />
      {children && (
        <div
          className={cc({
            [`${className}-sticky`]: !inView,
          })}
        >
          <div className={className}>{children}</div>
        </div>
      )}
    </Fragment>
  )
}

ToolbarTop.propTypes = {
  onScrollIntoView: func.isRequired,
  children: node,
  className: string,
}

export default ToolbarTop
