import { useSelector } from 'react-redux'

const HELP_ARTICLE_IDS = {
  IMAGE_GALLERY: {
    BEYOND: '4404751085714',
    NOW: '4405779304081',
  },
  IMAGE_FORMATS: {
    BEYOND: '4404301735826',
    NOW: '4405733124369',
  },
  COOKIES: {
    BEYOND: '360013606653',
    NOW: '360005519298',
  },
  GOOGLE_TAG_MANAGER: {
    BEYOND: '360012370759',
    NOW: '360006785998',
  },
  SHOP_LOGO: {
    BEYOND: '360008435414',
    NOW: '360005524537',
  },
  MENU_TYPE: {
    BEYOND: '9624321978012',
    NOW: '12567628514845',
  },
}

export default function useHelpCenterLink(articleType: keyof typeof HELP_ARTICLE_IDS): string {
  const interfaceLanguage = useSelector<State, string>((state) => state.getIn(['view', 'interfaceLanguage']))
  const isBeyond = useSelector<State, boolean>((state) => Boolean(state.getIn(['shop', 'beyond'])))

  const locale = formatLocaleForHelpcenter(interfaceLanguage, isBeyond)

  if (isBeyond) {
    return `https://online-help.zendesk.com/hc/${locale}/articles/${HELP_ARTICLE_IDS[articleType].BEYOND}`
  } else {
    return `https://helpspace.zendesk.com/hc/${locale}/articles/${HELP_ARTICLE_IDS[articleType].NOW}`
  }
}

function formatLocaleForHelpcenter(locale: string, isBeyond: boolean): string {
  if (isBeyond) {
    if (locale?.startsWith('de')) return 'de'
    if (locale?.startsWith('fr')) return 'fr'

    return 'en-us'
  } else {
    switch (locale) {
      case 'de':
        return 'de'
      case 'en':
        return 'en-gb'
      case 'fr':
        return 'fr-fr'
      case 'es':
        return 'es-es'
      case 'it':
        return 'it'
      case 'nl':
        return 'nl'
      default:
        return 'en-gb'
    }
  }
}
