type Props = Readonly<{
  headerAnnouncementSettings: {
    announcementHtml: string
  }
}>

export default function HeaderAnnouncementBar({ headerAnnouncementSettings }: Props) {
  return (
    <section className="header-announcement-bar">
      <div
        className="header-announcement-bar-wrapper"
        dangerouslySetInnerHTML={{ __html: headerAnnouncementSettings.announcementHtml }}
      ></div>
    </section>
  )
}
