import type { FunctionComponent } from 'react'

import MegaMenu from './MegaMenu'
import NestedMenu from './NestedMenu'
import OffCanvas from './OffCanvas'
import OverlayMenu from './OverlayMenu'

const menuTypeMap: Record<ThemeMenuTypes, FunctionComponent<any>> = {
  nestedMenu: ThemeNestedMenu,
  megaMenu: ThemeMegaMenu,
}

type MenuProps = {
  navigation: Frontend.NestedPage[]
}

type AdditionalMenuProps = {
  offCanvasDirection?: 'left' | 'right'
  withoutHomePage?: boolean
}

type Props = {
  shop: Core.FullShop
  themeSettings: Theme.Settings
  navigation: Frontend.NestedPage[]
}

export default function ThemeHeaderMenu(props: Props) {
  const {
    themeSettings,
    shop: { theme },
    navigation,
  } = props

  const selectedMenuType: ThemeMenuTypes = themeSettings?.themeHeader?.menuType
  const selectedHeaderType: ThemeHeaderTypes = themeSettings?.themeHeader?.headerType

  const Menu =
    selectedHeaderType === 'oneRow'
      ? theme.name.startsWith('epages.vision')
        ? ThemeVisionOffCanvasMenu
        : ThemeOverlayMenu
      : menuTypeMap[selectedMenuType]

  const additionalMenuProps: AdditionalMenuProps = {}

  if (theme.name.startsWith('epages.vision') && selectedHeaderType !== 'oneRow') {
    additionalMenuProps.offCanvasDirection = 'right'
    additionalMenuProps.withoutHomePage = true
  }

  return <Menu navigation={navigation} {...additionalMenuProps} />
}

function ThemeMegaMenu({
  navigation,
  offCanvasDirection = 'left',
  withoutHomePage = false,
}: MenuProps & AdditionalMenuProps) {
  return (
    <nav className="navigation" role="navigation">
      <OffCanvas
        className="navigation-mobile"
        items={withoutHomePage ? navigation.slice(1) : navigation}
        direction={offCanvasDirection}
      />
      <MegaMenu className="navigation-desktop" items={withoutHomePage ? navigation.slice(1) : navigation} />{' '}
    </nav>
  )
}

function ThemeNestedMenu({
  navigation,
  offCanvasDirection = 'left',
  withoutHomePage = false,
}: MenuProps & AdditionalMenuProps) {
  return (
    <nav className="navigation" role="navigation">
      <NestedMenu desktopMediaQuery="(min-width: 992px)">
        <NestedMenu.Menu items={withoutHomePage ? navigation.slice(1) : navigation} />
      </NestedMenu>
      <OffCanvas
        className="navigation-mobile"
        items={withoutHomePage ? navigation.slice(1) : navigation}
        direction={offCanvasDirection}
      />
    </nav>
  )
}

function ThemeOverlayMenu({ navigation }: MenuProps) {
  return (
    <nav role="navigation">
      <OverlayMenu className="navigation-overlay" items={navigation} />
    </nav>
  )
}

function ThemeVisionOffCanvasMenu({ navigation }: MenuProps) {
  return (
    <nav role="navigation">
      <OffCanvas className="navigation-offcanvas" items={navigation.slice(1)} />
    </nav>
  )
}
