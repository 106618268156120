import Element from '../Element'

type Props = Readonly<{
  config: UnknownRecord
  data: ImmutableMap
  editorView: boolean
  onDataChange: (data: ImmutableMap) => void
}>

export default function IconWithText({ config, editorView, data, onDataChange }: Props) {
  return (
    <div className="dali-grid-row">
      {data.get('columns').map((column, index) => (
        <div key={index} className="dali-grid-column">
          <div className="dali-grid-element">
            <Element
              config={config}
              editorView={editorView}
              element={column.get('icon')}
              onElementChange={(element) => onDataChange(data.setIn(['columns', index, 'icon'], element))}
            />
          </div>
          <div className="dali-grid-element">
            <Element
              config={config}
              editorView={editorView}
              element={column.get('text')}
              onElementChange={(element) => onDataChange(data.setIn(['columns', index, 'text'], element))}
            />
          </div>
        </div>
      ))}
    </div>
  )
}

IconWithText.getElements = (data: Core.Block['data']) =>
  data.columns.flatMap((col: Record<string, Core.Plugin>) => [col.icon, col.text])
