import * as redux from 'redux'
import type { AxiosInstance } from 'axios'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'

import createApiCallMiddleware from './middlewares/apiCallMiddleware'
import defaultOptionsMiddleware from './middlewares/defaultOptionsMiddleware'
import emptyState from './emptyState'
import mainReducer from './reducers'

export function createStore(api?: AxiosInstance | null, initialState: ImmutableMap = emptyState) {
  const middlewares: redux.Middleware[] = [
    thunk.withExtraArgument(api),
    defaultOptionsMiddleware(),
    createApiCallMiddleware(api),
  ]
  const store = redux.createStore<ImmutableMap, redux.Action, { api?: typeof api; dispatch: unknown }, any>(
    mainReducer,
    initialState,
    composeWithDevTools(redux.applyMiddleware(...middlewares)),
  )

  store.api = api
  return store
}
