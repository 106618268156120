import type { CSSProperties } from 'react'
import { Form } from '@epages/react-components'
import { createElement, useRef, useState } from 'react'
import Immutable from 'immutable'
import cc from 'classcat'
import loadable from '@loadable/component'

import ImageField, { withImageData, withMergedImageData } from './ImageField'
import CtaTextOverlay from '../components/CtaTextOverlay'
import LazyImage from '../../../../LazyImage'
import compose from '../../../../../utils/compose'
import translate from '../../../../../utils/translate'
import useHelpCenterLink from '../../../../../utils/hooks/useHelpCenterLink'
import useUpdateSettingsLayerPosition from '../../../../../utils/hooks/useUpdateSettingsLayerPosition'
import withI18n from '../../../../withI18n'

const SettingsLayer = loadable(() => import(/* webpackChunkName: "editor" */ '../../SettingsLayer'))
const Settings = loadable(() => import(/* webpackChunkName: "editor" */ './ImageSettings'))
const CropSettings = loadable(() => import(/* webpackChunkName: "editor" */ './ImageCropSettings'))

const defaultData = Immutable.fromJS({
  src: '',
  width: null,
  height: null,
  alt: '',
  link: '',
  opentab: false,
  text: '',
  buttontext: '',
  headline: '',
  buttonenabled: false,
})

type Props = {
  editAction: 'crop' | undefined
} & WorkspacePluginProps &
  TranslateProps

function ImagePlugin({
  config,
  editorView,
  editorMode,
  editAction,
  data = defaultData,
  onDataChange,
  onSave,
  onCancel,
  t,
}: Props) {
  const [isSettingActive, setIsSettingActive] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const ref = useRef<HTMLDivElement>(null)
  const updateSettingsLayerPosition = useRef<VoidFunction>()
  const helpCenterUrl = useHelpCenterLink('IMAGE_FORMATS')

  const isUnsupportedMediaType = error?.code === 415
  const isEmpty = !data.get('src')
  const isLinked = Boolean(data.get('link'))
  const pluginActiveClasses = cc([
    'dali-plugin-image',
    {
      'dali-grid-element-highlighted': isSettingActive,
    },
  ])

  useUpdateSettingsLayerPosition(updateSettingsLayerPosition)

  const handlePluginActiveStateChange = (isActive: boolean) => {
    setIsSettingActive(isActive)
  }

  const handleImageLoaded = () => {
    if (editorMode === 'edit') {
      updateSettingsLayerPosition.current?.()
    }
  }

  const emptyImage = (
    <div className="dali-grid-element-placeholder">
      <div className="dali-settingslayer-element">
        {error ? (
          <div className="dali-notification-danger">
            {error.message}{' '}
            {isUnsupportedMediaType && (
              <a
                href={helpCenterUrl}
                target="_blank"
                className="ep-form-row-text-external-link"
                rel="noreferrer noopener"
              >
                {t('components.imageUploadComponent.imageField.errorMessages.helpCenterLink')}
              </a>
            )}
          </div>
        ) : null}
      </div>
      <Form
        name="pluginImagePlaceholder"
        value={withImageData(data)}
        prepare={withImageData}
        onChange={(data) => onSave(withMergedImageData(data).remove('imageData'))}
        className="dali-form"
      >
        <ImageField
          withImageInfo
          name="imageData"
          onError={(error: Error) => setError(error)}
          onChange={() => setError(null)}
          storeFile={config?.storeFile}
        />
      </Form>
    </div>
  )

  function renderImage(isLinked) {
    const imageSource = config?.imageUrl ? config.imageUrl(data.get('src')) : data.get('src')
    const imageContentStyle = {
      '--aspect-ratio': data.get('width') && data.get('height') ? data.get('width') / data.get('height') : null,
    } as CSSProperties

    const textSettingsCta = {
      text: data.get('text'),
      headline: data.get('headline'),
      buttontext: data.get('buttontext'),
      buttonenabled: data.get('buttonenabled'),
    }

    const image = (
      <>
        <div className="dali-plugin-image-content" style={imageContentStyle}>
          <LazyImage
            src={imageSource}
            width={data.get('width')}
            height={data.get('height')}
            alt={data.get('alt')}
            onLoad={handleImageLoaded}
          />
        </div>
        <CtaTextOverlay textSettings={textSettingsCta} image={data} />
      </>
    )

    if (isLinked) {
      const linkTarget = data.get('opentab') ? '_blank' : undefined
      const rel = linkTarget ? 'noopener noreferrer' : undefined

      return (
        <a href={data.get('link')} target={linkTarget} rel={rel} className="dali-image-wrapper">
          {image}
        </a>
      )
    }

    return <div className="dali-image-wrapper">{image}</div>
  }

  const renderSettingsLayer = () => {
    return (
      <SettingsLayer
        referenceElement={ref.current}
        placement="right"
        onActiveStateChange={handlePluginActiveStateChange}
        onEscapeKeyDown={onCancel}
        className={editAction === 'crop' ? 'dali-settingslayer-image-crop' : null}
      >
        {({ updatePosition, renderLayout }) => {
          updateSettingsLayerPosition.current = updatePosition

          return createElement(editAction === 'crop' ? CropSettings : Settings, {
            data,
            onDataChange,
            config,
            onCancel,
            renderLayout,
            updateSettingsLayerPosition: updatePosition,
            onSave: (data) => {
              setError(null)
              onSave(data)
            },
          })
        }}
      </SettingsLayer>
    )
  }

  if (editorView) {
    return (
      <div className={pluginActiveClasses} ref={ref}>
        {isEmpty ? emptyImage : renderImage(false)}
        {editorMode === 'edit' && renderSettingsLayer()}
      </div>
    )
  }

  return !isEmpty ? <div className={pluginActiveClasses}>{renderImage(isLinked)}</div> : null
}

ImagePlugin.actionBarButtons = { edit: true }

export default compose(withI18n('interface'), translate())(ImagePlugin)
