// Note: Keep in sync with fusion-backend-ts/src/utils/themes
export const themeGroupDelimiter = ':'

/**
 * Gets the path to a stylesheet for given theme via `themeName`.
 * Defaults to the main stylesheet path when `fileNameSuffix` is not given.
 * Example file name suffix: "-ep6" for the theme‘s checkout and customer area stylesheet.
 */
// Note: Keep in sync with fusion-backend-ts/src/utils/themes
export function getThemeStylesheetPath(themeName: string, fileNameSuffix = ''): string {
  // File name convention:
  // If it‘s a standalone theme, default file name to "style".
  // If it‘s a theme inside a theme group directory, look for a file with its scoped name.
  const [directoryName, fileName = 'style'] = themeName.split(themeGroupDelimiter)

  return `/themes/${directoryName}/stylesheets/${fileName}${fileNameSuffix}.css`
}

/**
 * Gets the href to the stylesheet of the currently applied theme for given shop.
 */
export function getThemeStylesheetHref(shop: ImmutableMap, previewTheme?: string) {
  const themeStylesheetPath = getThemeStylesheetPath(shop.getIn(['theme', 'name']))
  const themeStylesheetPathWithSettings = `${themeStylesheetPath}?settings=${encodeURIComponent(
    JSON.stringify(shop.get('themeSettings').toJS()),
  )}`

  // - To avoid looking up theme settings in the database, pass all settings if the URL isn’t too long
  //   (up to 2000 characters is still long but should be safe). Avoids "414 Request-URI too large" error.
  // - If the URL is too long and we’re in theme preview mode, pass the `previewTheme` query parameter so
  //   that theme settings of the preview theme (and not of the active theme) are used from the database.
  //
  // See the `serveThemeStyles` middleware for more information.
  return themeStylesheetPathWithSettings.length < 1900
    ? themeStylesheetPathWithSettings
    : previewTheme
    ? `${themeStylesheetPath}?previewTheme=${previewTheme}`
    : themeStylesheetPath
}

/**
 * Gets the preview image path for given theme.
 *
 * @param themeName Name of the theme to get the preview image for.
 * @param hashedThemeAssets The theme‘s hashed theme assets from store state.
 */
export function getThemePreviewImagePath(themeName: string, hashedThemeAssets: ImmutableMap): string {
  // File name convention:
  // If it‘s a standalone theme, default file name to "preview".
  // If it‘s a theme inside a theme group directory, look for a file with its scoped name.
  const [directoryName, fileName = 'preview'] = themeName.split(themeGroupDelimiter)
  const hashedFileName = hashedThemeAssets.getIn([`${directoryName}/img`, `${fileName}.jpg`])

  return `/themes/${directoryName}/img/${hashedFileName}`
}

/**
 * Mapping of old header type names with their name.
 *
 * This is needed until the old header type names are no longer in use
 * (i.e. not in the database of any platform).
 */
const replacementHeaderTypes: Record<string, string> = {
  neutral: 'threeRows',
  vision: 'oneRowLogoLeft',
}

/**
 * Gets the normalized header type name for use as class name, etc.
 *
 * This is needed until the old header type names are no longer in use
 * (i.e. not in the database of any platform).
 */
export function getNormalizedHeaderType(headerType: string): string {
  return replacementHeaderTypes[headerType] || headerType
}
