import type { ComponentType } from 'react'
import type { LoadableComponent } from '@loadable/component'
import type { RouterState } from 'react-router'

import { createT } from './translate'
import i18n from '../i18next'

/** Gets data for insertion of `<link>` tags in HTML `<head>` */
export async function getLinkDataForRoute(routerProps: RouterState, storeState: State) {
  return aggregateRouteDataForStaticViewProp<View.Link>('link', routerProps, storeState)
}

/** Gets data for insertion of `<title>` and `<meta>` tags in HTML `<head>` */
export async function getMetaDataForRoute(routerProps: RouterState, storeState: State) {
  const shopI18n = i18n.cloneInstance()
  shopI18n.changeLanguage(storeState.getIn(['shop', 'locale']).replace('_', '-'))
  const t = createT(shopI18n, Boolean(storeState.getIn(['shop', 'beyond'])))

  // Get the data from the view.
  // Also provide `t` function so that the view can use translated values for the `<title>` tag.
  const meta = await aggregateRouteDataForStaticViewProp<View.Meta>('meta', routerProps, storeState, t)

  // If there is a view error, replace the title with a specific error title.
  // Or just add the specific error title if there is no title yet.
  if (storeState.getIn(['view', 'error'])) {
    const titleIndex = meta.findIndex((entry) => entry.title)
    meta.splice(titleIndex - meta.length, titleIndex === -1 ? 0 : 1, {
      title: t('views.storefrontView.errorPageSection.errorcode', {
        code: storeState.getIn(['view', 'error', 'statusCode']),
      }),
    })
  }

  // Use the shop title as default title.
  if (!meta.some((entry) => entry.title)) {
    meta.unshift({ title: storeState.getIn(['shop', 'title']) })
  }

  return meta
}

/** Gets chunk names for use with Loadable server-side rendering */
export async function getLoadableChunksForRoute(routerProps: RouterState, storeState: State) {
  return aggregateRouteDataForStaticViewProp<View.LoadableSsrChunk>('loadableSsrChunks', routerProps, storeState)
}

/**
 * Gets combined data for given static prop for all current route components (via routerProps).
 *
 * For example: For a product page, this function will collect all static prop data for the parent
 * `Storefront` route component as well as the `Product` route component. The individual results
 * are combined and returned as one result.
 */
async function aggregateRouteDataForStaticViewProp<T>(propName: string, routerProps: RouterState, ...other: unknown[]) {
  const data: T[] = []

  for (let Component of routerProps.components) {
    // Valid route components can be a function or an object (LoadableComponent).
    // `routerProps.components` can still include `undefined` values. Skip them.
    if (typeof Component === 'undefined') continue

    // The component might be loadable.
    // If loadable, then load it to get access to the static prop.
    const loadComponent = (Component as LoadableComponent<unknown>).load
    if (Component && typeof loadComponent === 'function') {
      Component = await loadComponent()

      // Loadable components have the signature { default: Component }
      Component = (Component as unknown as { default: ComponentType }).default
    }

    if (typeof Component[propName] === 'function') {
      data.push(...Component[propName](routerProps, ...other))
    } else if (Array.isArray(Component[propName])) {
      data.push(...Component[propName])
    }
  }

  return data
}
