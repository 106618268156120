import { isEmpty, keyBy } from 'lodash'
import Immutable from 'immutable'

import * as actions from '../actions'
import type { ProductDataType } from '../../components/templateComponents/Workspace/plugins/category/CategoryPlugin'
import { extendProductDataWithUrls } from '../../urlGenerators'
import { getPlain } from '../utils'

/**
 * Replace elements from an array with elements from an another array starting at a provided position
 * @param {Array} array original array
 * @param {Array} secondaryArray array to be inserted into the original array
 * @param {Number} startPosition position to start the replacing at
 */
export const replaceElementsWithArray = (array: any[], secondaryArray: any[], startPosition: number): any[] => {
  array.splice(startPosition, secondaryArray.length, ...secondaryArray)
  return array
}

export interface CategoryProductDataType extends Core.PageableProductResult {
  categoryId: string
  sort: string
  facets?: Core.Facets
}

export interface SearchDataType extends Core.PageableSearchResult {
  query: string
  sort: string
}

function getFacets(newFacets: Core.Facets, oldFacets: Core.Facets, filters: Core.Filter[]): Core.Facets {
  const parsedFacets: Core.Facets = {}
  Object.keys(newFacets).forEach(
    (key) =>
      (parsedFacets[key] = oldFacets[key] ? { ...newFacets[key], active: oldFacets[key].active } : newFacets[key]),
  )
  // preserve the values of previosly selected facets in order
  // no to loose any of the user selection
  const filterIds: string[] = filters ? filters.map((filter) => filter.id) : []
  Object.entries(oldFacets)
    .filter(([oldFacetId]) => filterIds.includes(oldFacetId))
    .forEach(([oldFacetId, oldFacet]) => {
      if (oldFacet.type === 'selection') {
        // collect all selected values
        const selectedValues = {}
        Object.entries(oldFacet.values).forEach(([valueId, value]) => {
          // set the number of matches to 0 cause only non empty values
          // will be returned from the API every time
          if (value.selected) selectedValues[valueId] = { ...value, matches: 0 }
        })
        if (!isEmpty(selectedValues)) {
          // add facet with previously selected values in case it has not been returned by the API
          if (isEmpty(parsedFacets[oldFacetId])) parsedFacets[oldFacetId] = { ...oldFacet, values: selectedValues }
          else {
            // add previously selected but missing values to an existing facet
            Object.entries(selectedValues).forEach(([selectedValueId, selectedValue]) => {
              if (isEmpty(parsedFacets[oldFacetId].values?.[selectedValueId]))
                // Typescript does not recognize the condition above as condition for values.
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                parsedFacets[oldFacetId].values![selectedValueId] = selectedValue
            })
          }
        }
      } else if (oldFacet.type === 'range') {
        // add missing range in case it has not been returned by the API
        if (oldFacet.values?.selected && isEmpty(parsedFacets[oldFacetId])) parsedFacets[oldFacetId] = oldFacet
      }
    })
  return parsedFacets
}

export default function products(state: State, action: actions.AllActionTypes): State {
  switch (action.type) {
    case actions.SET_PRODUCTS_FOR_CATEGORY: {
      const oldData = getPlain<ProductDataType>(
        state.getIn(['categoryProductData', action.payload.categoryId], { products: [] }),
      )
      const newFacets = action.payload.productResult.facets || {}
      const productData = state.getIn(['categoryProductData', action.payload.categoryId])
      const oldFacets = productData?.facets
      const facets =
        !oldFacets || state.getIn(['location', 'action']) !== 'REPLACE'
          ? newFacets
          : getFacets(newFacets, oldFacets, action.payload.filters)

      const newData: CategoryProductDataType = {
        ...oldData,
        categoryId: action.payload.categoryId,
        sort: action.payload.sort,
        totalNumberOfProducts: action.payload.productResult.totalNumberOfProducts,
        defaultSort: action.payload.productResult.defaultSort,
        categoryType: action.payload.productResult.categoryType,
        products: action.payload.productResult.products,
        facets,
      }
      return state.setIn(['categoryProductData', action.payload.categoryId], extendProductDataWithUrls(newData))
    }
    case actions.ADD_PRODUCTS_FOR_CATEGORY: {
      const oldData = getPlain<ProductDataType>(
        state.getIn(['categoryProductData', action.payload.categoryId], { products: [] }),
      )
      const products = oldData.products
      const newFacets = action.payload.productResult.facets || {}
      const productData = state.getIn(['categoryProductData', action.payload.categoryId])
      const oldFacets = productData?.facets
      const facets =
        !oldFacets || state.getIn(['location', 'action']) !== 'REPLACE'
          ? newFacets
          : getFacets(newFacets, oldFacets, action.payload.filters)

      const newData: CategoryProductDataType = {
        ...oldData,
        categoryId: action.payload.categoryId,
        sort: action.payload.sort,
        totalNumberOfProducts: action.payload.productResult.totalNumberOfProducts,
        defaultSort: action.payload.productResult.defaultSort,
        categoryType: action.payload.productResult.categoryType,
        products: [...products, ...action.payload.productResult.products],
        facets,
      }
      return state.setIn(['categoryProductData', action.payload.categoryId], extendProductDataWithUrls(newData))
    }
    case actions.SET_PRODUCTS_FOR_SEARCH: {
      const { query, sort, searchResult, filters } = action.payload
      const newFacets = searchResult.facets || {}
      const oldFacets = getPlain<Core.Facets | undefined>(state.getIn(['searchData', 'facets']))
      const facets =
        !oldFacets || state.getIn(['location', 'action']) !== 'REPLACE'
          ? newFacets
          : getFacets(newFacets, oldFacets, filters)

      return state
        .setIn(['searchData', `${query}-${sort}`], extendProductDataWithUrls({ ...searchResult, query, sort }))
        .setIn(['searchData', 'facets'], facets)
    }
    case actions.ADD_PRODUCTS_FOR_SEARCH: {
      const { query, sort, searchResult, filters } = action.payload
      const oldData = getPlain<Core.PageableSearchResult>(
        state.getIn(['searchData', `${query}-${sort}`], { products: [] }),
      )
      const products = oldData.products

      const newFacets = searchResult.facets || {}
      const oldFacets = getPlain<Core.Facets | undefined>(state.getIn(['searchData', 'facets']))
      const facets =
        !oldFacets || state.getIn(['location', 'action']) !== 'REPLACE'
          ? newFacets
          : getFacets(newFacets, oldFacets, filters)

      const newData: SearchDataType = {
        ...oldData,
        sort,
        query,
        totalNumberOfProducts: action.payload.searchResult.totalNumberOfProducts,
        products: [...products, ...searchResult.products],
        facets: searchResult.facets,
      }

      return state
        .setIn(['searchData', `${query}-${sort}`], extendProductDataWithUrls(newData))
        .setIn(['searchData', 'facets'], facets)
    }
    case actions.LOAD_PRODUCTS_BY_IDS_SUCCESS: {
      return action.pluginId === 'productPlugin'
        ? state.mergeIn(
            ['productData', action.pluginId],
            Immutable.fromJS(keyBy(extendProductDataWithUrls(action.response).products, 'productId')),
          )
        : state.setIn(['productData', action.pluginId], Immutable.fromJS(extendProductDataWithUrls(action.response)))
    }
    case actions.LOAD_PRODUCTS_FOR_CROSSSELLING_SUCCESS: {
      const { totalNumberOfProducts, manualCrossSellingTitle, products } = action.response

      // define initial CrossSellingObject to use if it is not yet defined on the respective product
      const initialObject = {
        products: Array(totalNumberOfProducts).fill({
          isLoaded: false,
        }),
        totalNumberOfProducts,
        manualCrossSellingTitle,
      }
      // get crossSellingObject from Store
      const crossSellingObjectFromStore = state.getIn(['crossSellingProductData', action.productId])
      // fall back to initial object if the crossSellingObject is not yet defined in the store
      const crossSellingProducts = crossSellingObjectFromStore ? crossSellingObjectFromStore.toJS() : initialObject

      // set products to correct position in the products array on the cross selling object
      const targetIndex = ((action.query.page as number) - 1) * action.query.resultsPerPage
      crossSellingProducts.products = replaceElementsWithArray(
        crossSellingProducts.products,
        products.map((product) => ({ ...product, isLoaded: true })),
        targetIndex,
      )

      return state.setIn(
        ['crossSellingProductData', action.productId],
        Immutable.fromJS(extendProductDataWithUrls(crossSellingProducts)),
      )
    }
    default:
      return state
  }
}
