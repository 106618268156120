import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'

import Element from '../Element'
import blockTypes from './index'

/*
  Block that renders columns inside a row. A column can render block components
  as well as elements.
*/

export default function MultiColumnBlock({ config, editorView, data, onDataChange }) {
  return (
    <div className="dali-grid-row">
      {data.get('columns').map((column, index) => {
        const BlockComponent = blockTypes[column.get('type')]

        return (
          <div key={index} className="dali-grid-column">
            {BlockComponent ? (
              <BlockComponent
                config={config}
                editorView={editorView}
                data={column.get('data')}
                onDataChange={(block) => onDataChange(data.setIn(['columns', index, 'data'], block))}
                settings={column.get('settings')}
              />
            ) : (
              <Element
                config={config}
                editorView={editorView}
                element={column}
                onElementChange={(element) => onDataChange(data.setIn(['columns', index], element))}
              />
            )}
          </div>
        )
      })}
    </div>
  )
}

MultiColumnBlock.propTypes = {
  config: PropTypes.any.isRequired,
  editorView: PropTypes.any.isRequired,
  data: PropTypes.any.isRequired,
  onDataChange: PropTypes.func.isRequired,
  settings: ImmutablePropTypes.map,
}

MultiColumnBlock.getElements = (data) => data.columns
