export type PersonalDataField = {
  name: keyof Beyond.Address
  maxLength?: number
  required?: boolean
  autoComplete?: string
  hide?: boolean
  defaultValue?: string
}

export const personalDataFields: PersonalDataField[] = [
  { name: 'firstName', autoComplete: 'given-name', required: true, maxLength: 50, defaultValue: '' },
  { name: 'lastName', autoComplete: 'family-name', required: true, maxLength: 50, defaultValue: '' },
  { name: 'company', autoComplete: 'organization', required: false, maxLength: 100, defaultValue: '' },
  { name: 'houseNumber', autoComplete: 'address-line2', required: true, maxLength: 50, defaultValue: '' },
  { name: 'street', autoComplete: 'address-line1', required: true, maxLength: 200, defaultValue: '' },
  { name: 'addressExtension', autoComplete: 'address-line3', required: false, maxLength: 200, defaultValue: '' },
  { name: 'postalCode', autoComplete: 'postal-code', required: true, maxLength: 25, defaultValue: '' },
  {
    name: 'dependentLocality',
    autoComplete: 'address-level3',
    required: false,
    hide: true,
    maxLength: 60,
    defaultValue: '',
  },
  { name: 'city', autoComplete: 'address-level2', required: true, maxLength: 60, defaultValue: '' },
  { name: 'state', autoComplete: 'address-level1', required: false, hide: true, maxLength: 100, defaultValue: '' },
]

// The address fields for the countries below are ordered based on this wikipedia article:
// https://en.wikipedia.org/wiki/Address
const personalDataByCountry: { [country: string]: PersonalDataField[] } = {
  // United States
  US: [
    { name: 'firstName' },
    { name: 'lastName' },
    { name: 'company' },
    { name: 'houseNumber' },
    { name: 'street' },
    { name: 'addressExtension' },
    { name: 'city' },
    { name: 'state', required: true, hide: false },
    { name: 'postalCode' },
  ],
  // Great Britain
  GB: [
    { name: 'firstName' },
    { name: 'lastName' },
    { name: 'company' },
    { name: 'houseNumber' },
    { name: 'street' },
    { name: 'addressExtension' },
    { name: 'dependentLocality', hide: false },
    { name: 'city' },
    { name: 'postalCode' },
  ],
  // Ireland
  IE: [
    { name: 'firstName' },
    { name: 'lastName' },
    { name: 'company' },
    { name: 'houseNumber' },
    { name: 'street' },
    { name: 'addressExtension' },
    { name: 'city' },
    { name: 'postalCode', required: false },
  ],
  // Argentinia
  AR: [
    { name: 'firstName' },
    { name: 'lastName' },
    { name: 'company' },
    { name: 'street' },
    { name: 'houseNumber' },
    { name: 'addressExtension' },
    { name: 'postalCode' },
    { name: 'city' },
    { name: 'state', required: true, hide: false },
  ],
  // Germany
  DE: [
    { name: 'firstName' },
    { name: 'lastName' },
    { name: 'company' },
    { name: 'street' },
    { name: 'houseNumber' },
    { name: 'addressExtension' },
    { name: 'postalCode' },
    { name: 'city' },
  ],
  // Spain
  ES: [
    { name: 'firstName' },
    { name: 'lastName' },
    { name: 'company' },
    { name: 'street' },
    { name: 'houseNumber' },
    { name: 'addressExtension' },
    { name: 'postalCode' },
    { name: 'city' },
  ],
  // Russia
  RU: [
    { name: 'firstName' },
    { name: 'lastName' },
    { name: 'company' },
    { name: 'street' },
    { name: 'houseNumber' },
    { name: 'addressExtension' },
    { name: 'city' },
    { name: 'state', required: true, hide: false },
    { name: 'postalCode' },
  ],
  default: [
    { name: 'firstName' },
    { name: 'lastName' },
    { name: 'company' },
    { name: 'houseNumber' },
    { name: 'street' },
    { name: 'addressExtension' },
    { name: 'postalCode' },
    { name: 'city' },
    { name: 'state' },
  ],
}
;['AU', 'BR', 'CA', 'CN', 'IN', 'ID', 'IT', 'JP', 'MX', 'TH', 'UM'].forEach((key) => {
  personalDataByCountry[key] = [
    ...personalDataByCountry.default.filter(({ name }) => name !== 'state'),
    { name: 'state', required: true, hide: false },
  ]
})

export default function personalDataFieldsByCountry(country: string): PersonalDataField[] {
  if (personalDataByCountry[country])
    return personalDataByCountry[country].map((field) => ({
      ...personalDataFields.find((f) => f.name === field.name),
      ...field,
    }))

  return personalDataFields
}
